import { render, staticRenderFns } from "./CompanyCourses.vue?vue&type=template&id=ada63676&scoped=true"
import script from "./CompanyCourses.vue?vue&type=script&lang=js"
export * from "./CompanyCourses.vue?vue&type=script&lang=js"
import style0 from "./CompanyCourses.vue?vue&type=style&index=0&id=ada63676&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ada63676",
  null
  
)

export default component.exports