<template>
  <div class="content" v-loading.fullscreen.lock="loading">
    <base-header class="pb-6">
      <div class="row align-items-center py-2"></div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="" footer-classes="pb-2">
          <template slot="header">
            <div class="row align-items-center">
              <div class="col-md-12 text-left">
                <h2 class="mb-0">Purchase Course</h2>
              </div>
            </div>
          </template>
          <div>
            <div
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            ></div>
            <div>
              <el-table
                :data="queriedData"
                row-key="id"
                role="table"
                class="empcoursesGrid"
                header-row-class-name="thead-light custom-thead-light"
              >
                <el-table-column min-width="200px" label="Course Name">
                  <template slot-scope="props">
                    <span>{{ props.row.name }}</span>
                  </template>
                </el-table-column>

                <el-table-column min-width="200px" label="Cost" prop="cost">
                  <template slot-scope="props">
                    <span>{{ props.row.cost }}</span>
                  </template>
                </el-table-column>
                <el-table-column min-width="150px" label="Action">
                 <div slot-scope="{ $index, row }">
                    <el-tooltip  content="Purchase Course" placement="top">
                      <base-button
                        @click.native="purchaseCourse($index, row)"
                        type="success"
                        class="custom-btn"
                        size="sm"
                        data-toggle="tooltip"
                        data-original-title="Purchase Course"
                      >
                        Purchase
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </card>
      </div>
    </div>
    <modal :show.sync="purchaseCourseModel">
      <h4 slot="header" style="color:#444C57" class="modal-title mb-0">
        Payment for {{course_name}}
      </h4>

      <pay-by-employee
        type="purchaseCourse"
        :amountPayable="amountPayable"
        :orignalAmount="originalAmount"
        :discount="discount"
        address=""
        city=""
        state=""
        zipcode=""
        v-on:payClicked="payClicked"
      />
    </modal>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import clientPaginationMixin from "../Tables/PaginatedTables/clientPaginationMixin";
import PayByEmployee from "./PayByEmployee.vue";
import Swal from "sweetalert2";
export default {
  mixins: [clientPaginationMixin],
  components: {
    PayByEmployee,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      loading: false,
      tableData: [],
      purchaseCourseModel:false,
      amountPayable:0,
      originalAmount:0,
      discount:0,
      course_id:0
    };
  },

  created() {
    this.fetchData();
  },
  methods: {
  
    fetchData() {
      this.loading = true;
      this.$http
        .get("employees/not_purchased_courses")
        .then((resp) => {
          this.tableData = [];
          this.tableData = resp.data;
        })
        .finally(() => (this.loading = false));
    },

    purchaseCourse(index, row) {
        this.purchaseCourseModel= true;
        this.amountPayable= row.cost;
        this.course_id= row.id;
        this.course_name= row.name;
    },
    payClicked(cardData, addressData){
      this.loading=true;
      this.formData={};
      let payment = {
        payment_type: "purchaseCourse",
        description: "Course Purchased by employee",
        cardholder_street_address: "",
        cardholder_zip: "",
        cardholder_city: "",
        cardholder_state: "",
        transaction_amount: "",
        token: cardData.token 
      };
      if (addressData.address != "") {
        payment.cardholder_street_address = addressData.address;
        payment.cardholder_zip = addressData.zipcode;
        payment.cardholder_city = addressData.city;
        payment.cardholder_state = addressData.state;
      } else {
        payment.cardholder_street_address = this.address;
        payment.cardholder_zip = this.zipcode;
      }
      payment.transaction_amount = this.amountPayable.toFixed(2);
      this.formData.payment = payment;
     
      this.formData.course_id = this.course_id;


       this.$http
        .post("employees/purchase_course",this.formData )
        .then(resp => {
        this.loading=false;
            Swal.fire({
              title: "Success!",
              text: "Course Purchased Successfully.",
              icon: "success"
            }).then(result => {
              this.purchaseCourseModel= false;
              this.fetchData();
          });
        })
        .catch(function(error) {
          if (error.response.status === 422) {
            this.loading=false;
             Swal.fire({
              title: "Error!",
              text: error.response.data.message,
              icon: "error"
            });
             this.loading=false;
          }
        }).finally(() => (this.loading = false));

    }
  },
};
</script>
<style scoped>
.no-border-card .card-footer {
  border-top: 0;
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .empcoursesGrid >>> table.el-table__body td:nth-of-type(1):before {
    content: "Course Name";
  }
  .empcoursesGrid >>> table.el-table__body td:nth-of-type(2):before {
    content: "Cost";
  }
  .empcoursesGrid >>> table.el-table__body td:nth-of-type(3):before {
    content: "Action";
  }
}
</style>
